import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import axios from "axios"
import InputMask from "react-input-mask"
import { Container, Row, Col } from "react-bootstrap"

import RefButton from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefButton"
// import UploadButton from "../../../Elements/Buttons/RefButtons/RefStyledButtons/RefUpload"

const ModalForm = props => {
  const WEBSITE_URL = "https://ref.vgroshev.ru"
  const FORM_ID = "a179968" //Form id that provides Contact Form 7

  const [token, setToken] = useState() // store token
  const [uploadMsg, setUploaded] = useState("")
  const [isSuccessMessage, setIsSuccessMessage] = useState(false) // manage is success message state
  const [messageSent, setMessageSent] = useState(false) // manage sent message state

  // this effect function authenticates our subcriber user to get a token
  useEffect(() => {
    axios({
      method: "post",
      url: `${WEBSITE_URL}/wp-json/jwt-auth/v1/token`,
      data: {
        username: "refengSubscriber", // provide a user credential with subscriber role
        password: "cYa76#Kw*12$",
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setToken(response.data.token)
      })
      .catch(error => console.error("Error", error))
  }, [])

  // UploadA File
  const activateFileInput = file => {
    const inputFile = document.getElementById("form-file")
    inputFile.click()
    setInterval(function () {
      if (document.getElementById("add-file-button") === null) {
        return
      }
      if (inputFile.files[0]) {
        document.getElementById("add-file-button").classList.add("add")
        setUploaded("Файл загружен")
      }
    }, 1000)
  }

  // use useFormik hook using object destructuring assignment to extract helpful methods
  const {
    handleChange,
    isSubmitting,
    values,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      phone: "",
      file: "",
      message: "",
    },
    onSubmit: (
      { fullname, email, phone, file, message },
      { setSubmitting, resetForm }
    ) => {
      setSubmitting(true)
      // here we created a FormData field for each form field
      const bodyFormData = new FormData()
      bodyFormData.set("fullname", fullname)
      bodyFormData.set("email", email)
      bodyFormData.set("phone", phone)
      bodyFormData.append("file-141", file)
      bodyFormData.set("message", message)

      // here we sent
      axios({
        method: "post",
        url: `${WEBSITE_URL}/wp-json/contact-form-7/v1/contact-forms/${FORM_ID}/feedback`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(response => {
          // actions taken when submission goes OK
          resetForm()
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(true)
        })
        .catch(error => {
          // actions taken when submission goes wrong
          setSubmitting(false)
          setMessageSent(true)
          setIsSuccessMessage(false)
        })
        .then(() => {
          props.onHide()
        })
    },
  })

  useEffect(() => {
    // set timeout 3 seconds to remove error/success message.
    setTimeout(() => {
      // this will reset messageSent and isSuccessMessage state
      setMessageSent(false)
      setIsSuccessMessage(false)
    }, 3000)
    // this effect function will be dispatched when isSuccessMessage or messageSent changes its state
  }, [isSuccessMessage, messageSent])

  return (
    <Container>
      <Row>
        <Col>
          <form onSubmit={handleSubmit} className="refeng-form">
            <fieldset>
              <Row>
                <Col xl={6}>
                  <div>
                    {/* <label htmlFor="fullname">Ваше имя*</label> */}
                    <input
                      id="fullname"
                      name="fullname"
                      type="text"
                      placeholder="Ваше имя*"
                      onChange={handleChange}
                      value={values.fullname}
                      required
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="email">E-mail*</label> */}
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="E-mail*"
                      onChange={handleChange}
                      value={values.email}
                      required
                    />
                  </div>
                  <div>
                    {/* <label htmlFor="phone">Телефон*</label> */}
                    <InputMask
                      mask="+7-999-999-99-99"
                      id="phone"
                      name="phone"
                      type="phone"
                      placeholder="Телефон*"
                      onChange={handleChange}
                      value={values.phone}
                      required
                    />
                  </div>
                </Col>
                <Col xl={6}>
                  <div>
                    {/* <label htmlFor="message">Сообщение*</label> */}
                    <textarea
                      id="message"
                      name="message"
                      type="text"
                      placeholder="Сообщение*"
                      onChange={handleChange}
                      value={values.message}
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row className="modal-row">
                <input
                  type="file"
                  name="file-141"
                  id="form-file"
                  style={{ display: "none" }}
                  onChange={event => {
                    setFieldValue("file", event.currentTarget.files[0])
                  }}
                />
                <Col className="form-buttons">
                  <div
                    role="button"
                    tabIndex="-1"
                    aria-label="Upload file button"
                    className="btn-ref-upload"
                    id="add-file-button"
                    onClick={activateFileInput}
                    onKeyDown={activateFileInput}
                  >
                    Прикрепить
                  </div>
                </Col>
                <Col className="form-buttons">
                  <RefButton
                    type="submit"
                    value="Send Message"
                    disabled={isSubmitting}
                  >
                    Отправить
                  </RefButton>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label class="ref-checkbox">
                    <a
                      href="/agreement"
                      rel="noopener noreferrer"
                      target="_blank"
                      aria-label="Согласие"
                    >
                      &nbsp;согласие на обработку персональных данных
                    </a>
                    Отправляя данную форму, вы даете
                    <input
                      type="checkbox"
                      style={{ height: "2vh", width: "2vw" }}
                      required
                    />
                    <span class="checkmark"></span>
                  </label>
                </Col>
              </Row>
            </fieldset>

            <Row>
              <Col className="form-buttons">{uploadMsg}</Col>
              <Col className="form-buttons">
                {messageSent && (
                  <div className="form-sending-message">
                    Ваше сообщение отправляется!
                  </div>
                )}
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  )
}

export default ModalForm
